import React, { useEffect } from 'react';

import './App.scss';
// import Preloader from './comp/preloader/Preloader';
import Location from './comp/location/Location';

function App() {

	window.onbeforeunload = function () {
		window.scrollTo(0, 0);
	};


	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		
		<main>
			
			{/* <Preloader /> */}

			<Location />


		</main>

	);
}

export default App;
